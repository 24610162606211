.App {
  text-align: center;
}

.App-header {
  display: flex;
  position:absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align:center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 20px;
  z-index:99990;
  top: 15px;
  left: 15px;
}
